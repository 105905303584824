<template>
    <inner-page-card heading="ADD BANK ADMIN">

        <s-form class="row" @submit="onSubmit" ref="formValidator">

            <div class="col-6">
                <validated-input label="Username" placeholder="Username" class="c-form-input"
                                 v-model="model.username" :rules="{required:true}"
                                 @blur="checkUsername(model.username)"/>
            </div>

            <div class="col-6">
                <validated-input label="Employee ID" placeholder="Example: ENP576281" class="c-form-input"
                                 v-model="model.employee_number"
                                 :rules="{required:true}"/>
            </div>

            <div class="col-6">
                <validated-input label="First Name" placeholder="First Name" class="c-form-input"
                                 v-model="model.first_name"
                                 :rules="{required:true}"/>
            </div>

            <div class="col-6">
                <validated-input label="Last Name" placeholder="Last Name" class="c-form-input"
                                 v-model="model.last_name"
                                 :rules="{required:true}"/>
            </div>

            <div class="col-6">
                <validated-input label="Mobile Number*" placeholder="Mobile number" class="c-form-input"
                                 v-model="model.mobile_number" type="number" name="Mobile number"
                                 :rules="{required:true, min:10, max:10}" @blur="checkMobile(model.mobile_number)"/>
            </div>

            <div class="col-6">
                <validated-input label="E-Mail ID*" placeholder="E-Mail ID" type="email"
                                 class="c-form-input" name="email" @input="customEmailValidate(model.email)"
                                 v-model="model.email" @blur="checkEmailExists(model.email)"
                                 :rules="{required:rules.email}"/>
                <p class="text-danger" v-if="invalidEmail && model.email">Entered domain address is not accepted.</p>
            </div>

            <div class="col-6">
                <validated-input label="Role" placeholder="Example: Database Manager" class="c-form-input"
                                 v-model="model.employee_role"
                                 :rules="{required:true}"/>
            </div>

            <div class="col-6">
                <validated-ajax-vue-select label="Bank" placeholder="" class="c-form-input"
                                           v-model="model.bank"
                                           :rules="{required:true}" :url="bankOptionUrl"/>
            </div>

            <div class="col-12">
                <div class="fl-x fl-j-c">
                    <btn text="Cancel" type="button" class="px-5" @click="$router.push({path: '/manage-users/0/'})"
                         design="basic-b" color="secondary"></btn>

                    <btn text="Add" loading-text="Adding..." :loading="loading" class="px-5 ml-3" design="basic-a"
                         color="secondary"></btn>
                </div>
            </div>

        </s-form>

        <modal class="c-modal no-close" :no-close-on-backdrop="false" title="" ref="SuccessModal" width="25r"
               header-color="">

            <div class="text-center">
                <div class="mb-4">
                    <img src="../../../assets/web/homee/icons/success-tick.png" class="icon-img-md" alt="">
                </div>

                <h4 class="mb-4 font-inter-bold">User Added</h4>

                <p class="font-inter-light mb-5">User <span v-html="model.first_name + ' ' + model.last_name"></span>
                    has been added as Bank Admin.</p>

                <btn text="Done" class="" block @click="$router.push({ path : '/manage-users/0/' });"></btn>
            </div>

        </modal>

    </inner-page-card>
</template>

<script>
import urls from '../../../data/urls';
import axios from 'secure-axios';

export default {
    name : 'AddBankAdminFirst',

    data () {
        return {
            loading      : false,
            invalidEmail : false,

            bankOptionUrl : urls.auction.bank.bankOptions,

            model : {
                username        : '',
                first_name      : '',
                last_name       : '',
                employee_role   : '',
                employee_number : '',
                mobile_number   : '',
                email           : ''
            },
            rules : {
                email : {
                    required : true
                }
            }
        };
    },

    methods : {

        async onSubmit () {
            this.loading = true;
            const response = await axios.form(urls.userList.bankAdmin.addEdit, this.model);
            if (response.data.success) {
                this.loading = false;
                await this.$refs.SuccessModal.show();
            } else {
                this.loading = false;
            }
        },

        checkUsername (username) {
            const that = this;
            const data = {
                username : username
            };

            axios.form(urls.userList.usernameCheck, data).then(function (response) {
                const json = response.data;
                if (json.error === false) {
                } else {
                    that.$refs.formValidator.setErrors({ Username : json.message });
                }
            }).catch(function (exception) {
                console.log('exception occurred during the checking for username availability : ', exception);
            });
        },

        customEmailValidate (value) {
            const allowedDomains = ['yahoo.com', 'gmail.com', 'outlook.com', 'hotmail.com'];
            const domain = value.split('@')[1];
            this.invalidEmail = allowedDomains.includes(domain);
        },

        checkEmailExists (email) {
            const that = this;
            const data = {
                email : email
            };
            axios.form(urls.userList.emailCheck, data).then(function (response) {
                const json = response.data;
                if (json.error === false) {
                } else {
                    if (!that.invalidEmail) {
                        that.$refs.formValidator.setErrors({ email : json.message });
                    }
                }
            }).catch(function (exception) {
                console.log('exception occurred during the checking for username availability : ', exception);
            });
        },

        checkMobile (mobile) {
            const that = this;
            const data = {
                mobile : mobile
            };

            axios.form(urls.userList.mobileCheck, data).then(function (response) {
                const json = response.data;
                if (json.error === false) {
                } else {
                    that.$refs.formValidator.setErrors({ 'Mobile number' : json.message });
                }
            }).catch(function (exception) {
                console.log('exception occurred during the checking for username availability : ', exception);
            });
        }
    }
};
</script>

<style scoped>

</style>
